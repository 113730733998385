import { Component } from '@angular/core';
import { GlobalService } from './shared/global.service';
import { Pagination } from './models';
import { SettingService } from './modules/commons/service/setting.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EVENTS } from './utils/constant';
import { UtilService } from './utils/util.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'web-delivery-marca-blanca';
  pagination: Pagination = {
    page: 1,
    size: 10,
    totalRecords: 0,
    totalNumberPages: 0,
  };
  constructor(
    private servi:SettingService,
    private utilServices:UtilService

  ) {}
  async ngOnInit() {
    this.servi.getIpId().subscribe((ip:any)=>{
      console.log(ip)
      this.utilServices.pixcel(EVENTS.PAGE_VIEW,{content_ids:[ip],content_name:ip})
      localStorage.setItem('ip',JSON.stringify(ip))
    },(erro:HttpErrorResponse)=>{
      if(erro.status==200){
        localStorage.setItem('ip',JSON.stringify(erro.error.text))
      }else{
        console.log(erro)
        
      }
    })
    
  }

}
